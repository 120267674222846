import _ from "lodash";
import {MoneyHelper} from "../../components/util/money";

function getTotalMonetaryFees(cpmFees, referenceCpm) {
  const currency = _.get(referenceCpm, 'currency');
  return _.reduce(cpmFees, function(sum, fee) {
    if (fee.type === 'MONETARY') {
      const monetaryFee = _.get(fee, ['monetary']);
      if (MoneyHelper.isMoney(monetaryFee) && monetaryFee.currency === currency) {
        return sum + monetaryFee.amount;
      }
    }
    if (fee.type === 'MULTI_CURRENCY_MONETARY') {
      const amounts = _.isArray(fee.multiCurrencyMonetaryAmounts) ? fee.multiCurrencyMonetaryAmounts : [];
      const matchingFee = _.find(amounts, function(amount) {
        return amount.currency === currency;
      });
      if (matchingFee && MoneyHelper.isMoney(matchingFee)) {
        return sum + matchingFee.amount;
      }
    }
    return sum;
  }, 0);
}

function getTotalPercentageFees(cpmFees) {
  return _.reduce(cpmFees, function(sum, fee) {
    if (fee.type === 'PERCENTAGE' && _.isFinite(_.get(fee, ['percentage']))) {
      return sum + fee.percentage;
    }
    return sum;
  }, 0);
}


export const calculateNetCpm = function(cpmFees, grossCpm) {
  const totalMonetaryFees = getTotalMonetaryFees(cpmFees, grossCpm);
  const totalPercentage = getTotalPercentageFees(cpmFees);
  const maxFee = _.find(cpmFees, function(fee) {
    return _.get(fee, ['monetary', 'maxAmount']);
  });

  let netCpm = {};
  if (MoneyHelper.isMoney(grossCpm)) {
    netCpm.currency = grossCpm.currency;
    netCpm.amount = grossCpm.amount - totalMonetaryFees - grossCpm.amount * totalPercentage / 100;
  }
  if (maxFee) {
    netCpm.maxAmount = _.cloneDeep(netCpm.amount);
    netCpm.amount = grossCpm.amount - totalMonetaryFees + maxFee.monetary.amount - maxFee.monetary.maxAmount - grossCpm.amount * totalPercentage / 100;
  }
  return netCpm;
};

export const deriveGrossTargetCpm = function(cpmFees, netCpmTarget) {
  const totalMonetaryFees = getTotalMonetaryFees(cpmFees, netCpmTarget);
  const totalPercentage = getTotalPercentageFees(cpmFees);

  let grossTargetCpm = {};
  if (MoneyHelper.isMoney(netCpmTarget)) {
    grossTargetCpm.currency = netCpmTarget.currency;
    grossTargetCpm.amount = (100 * (netCpmTarget.amount + totalMonetaryFees) / (100 - totalPercentage));
  }
  return grossTargetCpm;
};
