/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as angular from 'angular';

export class CreativeViewer {
  constructor(private $document) {
  }

  viewCreative(container, previewData): void {
    if (!container || !previewData || !previewData.creativeContent) {
      return;
    }

    // make sure links don't do anything in preview mode
    const insertIndex = previewData.creativeContent.indexOf("</head>");
    if (insertIndex > -1 && !previewData.allowScript) {
      const styleSheet = "<style>a { pointer-events: none !important; }</style>";
      previewData.creativeContent = previewData.creativeContent.substring(0, insertIndex) + styleSheet + previewData.creativeContent.substring(insertIndex);
    }

    const divElement = angular.element(container);
    divElement.attr('id', 'appPreviewIdContainer');

    const existingIframe = divElement.find("iframe");
    if (existingIframe && existingIframe.length) {
      existingIframe.detach();
    }

    const ifr = this.$document[0].createElement('iframe');
    ifr.allowTransparency = true;

    let transformStyle = "";
    const previewId = "appPreviewId";
    if (previewData.maxWidth && previewData.maxHeight && (previewData.maxWidth < previewData.creativeWidth || previewData.maxHeight < previewData.creativeHeight)) {
      divElement.attr('style', "width: " + previewData.maxWidth + "px;height:" + previewData.maxHeight + "px;");
      const width = previewData.creativeWidth,
        height = previewData.creativeHeight,
        ratio = width > height ? previewData.maxWidth / width : previewData.maxHeight / height;
      transformStyle = "zoom:(" + ratio + ");-moz-transform: scale(" + ratio + "); -moz-transform-origin: 0 0; -o-transform: scale(" + ratio + "); -o-transform-origin: 0 0; -webkit-transform: scale(" + ratio + "); -webkit-transform-origin: 0 0;";
    }

    ifr.setAttribute('style', previewData.showBorder ? 'outline: 3px solid #bbb;' : '' + 'display: block;margin: 0;borderWidth: 0; padding: 0;' + transformStyle);
    ifr.setAttribute('scrolling', 'no');
    ifr.setAttribute('id', previewId);
    if (previewData.creativeWidth) {
      ifr.setAttribute('width', previewData.creativeWidth);
    }
    if (previewData.creativeHeight) {
      ifr.setAttribute('height', previewData.creativeHeight);
    }
    ifr.frameBorder = "0";

    const sandboxTheIframe = !previewData.notSandbox && "sandbox" in ifr && "srcdoc" in ifr;
    if (!sandboxTheIframe && (!previewData.allowScript || previewData.notSandbox)) {
      ifr.setAttribute('src', '#?ifrId=' + previewId);
    } else {
      ifr.setAttribute("sandbox", "allow-same-origin");
    }

    if (sandboxTheIframe) {
      ifr.srcdoc = previewData.creativeContent;
      ifr.setAttribute("sandbox", "allow-popups allow-pointer-lock allow-top-navigation allow-forms allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation");

      if (previewData.allowScript) {
        ifr.sandbox += " allow-scripts";
      }
      divElement.append(ifr);
    } else {
      divElement.append(ifr);
      const iframeDoc = ifr.contentDocument || ifr.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(previewData.creativeContent);
      iframeDoc.close();

      const iframeElement = angular.element(iframeDoc);
      const bodyElement = iframeElement.find("body");
      bodyElement.attr('style', 'margin: 0; padding: 0;');
    }
  }
}
